import { useLocation, useNavigate } from "react-router-dom";
import "./style.scss";

export default () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const isEdit = query.get("edit") === "true";

  function goToMijnBestellingen(e: React.MouseEvent) {
    e.preventDefault();
    navigate("/bestellingen");
  }

  return (
    <>
      <h1>Bestelling {isEdit ? "bewerkt" : "verstuurd"}!</h1>
      <iframe title="ok gif" src="https://giphy.com/embed/uyoXx0qpUWfQs" width="480" height="331" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>

      <button className="afterFormSubmit" onClick={goToMijnBestellingen}>
        Mijn bestellingen
      </button>
    </>
  );
}