import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import Login from './components/auth/Login';
import { AuthProvider } from "./config/auth";
import Layout from './components/Layout';
import ProtectedRoute from './components/auth/ProtectedRoute';
import AdminDashboard from './components/AdminDashboard';
import AdminGebruikers from './components/AdminDashboard/AdminGebruikers';
import BesteldePrints from './components/AdminDashboard/BesteldePrints';
import FirefoxFix from './components/FirefoxFix';
import Printformulier from './components/Bestelformulier';
import BestellingVerstuurd from './components/BestellingVerstuurd';
import BestellingDetail from './components/AdminDashboard/BestellingDetail';
import Rekeningen from './components/AdminDashboard/Rekeningen';
import MijnBestellingen from './components/MijnBestellingen';
import EditBestelling from './components/MijnBestellingen/EditBestelling';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/invalidLogin" element={
            <span>Deze website is enkel toegankelijk voor leerkrachten van het Scheppersinstituut Deurne en Antwerpen.</span>
          } />
          <Route element={<Layout />}>
            <Route path="/login" element={<Login />} />
          </Route>
          <Route element={<ProtectedRoute><Layout /></ProtectedRoute>}>
            <Route path="/" element={<Printformulier />} />
            <Route path="/bestellingVerstuurd" element={<BestellingVerstuurd />} />
            <Route path="/bestellingen" element={<MijnBestellingen />} />
            <Route path="/bestellingen/:bestellingId" element={<EditBestelling />} />
            <Route path="/admin" element={<ProtectedRoute adminOnly={true}><AdminDashboard /></ProtectedRoute>}>
              <Route index element={<BesteldePrints/>} />
              <Route path="gebruikers" element={<AdminGebruikers />}/>
              <Route path="rekeningen" element={<Rekeningen />}/>
              <Route path=":bestellingId" element={<BestellingDetail />}/>
            </Route>
            <Route path="/firefoxFix" element={<FirefoxFix />} />
          </Route>
          <Route path="*" element={<>
            Deze pagina bestaat niet.
            Klik <Link to="/">hier</Link> om terug te gaan naar de beginpagina. 
          </> } />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
);