import "./style.scss";

type Props = {
  name: string;
  errors: Map<string, string>;
};

export default ({ name, errors }: Props) => {
  if (!errors.has(name)) {
    return null;
  }

  return (
    <span className="validationError">{errors.get(name)}</span>
  );
}