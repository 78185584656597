import Dropzone, { DropzoneProps } from "react-dropzone";
import uploadIcon from "./upload.png";
import plusIcon from "./plus.png";
import rejectIcon from "./reject.png";
import okIcon from "./ok.png";
import "./style.scss";

type Props = DropzoneProps & { document: File | undefined };

function dragIcon(
  document: File | undefined,
  isDragActive: boolean,
  isDragReject: boolean,
  bestandTeGroot: boolean,
) {
  if (bestandTeGroot) {
    return rejectIcon;
  }

  if (isDragActive) {
    if (isDragReject) {
      return rejectIcon;
    } else {
      return plusIcon;
    }
  }

  if (document !== undefined) {
    return okIcon;
  }
  
  return uploadIcon;
}

export default (props: Props) => {
  const { document } = props;
  const maxSize = 2**20*10; // 10 MiB

  return (
    <Dropzone
      accept={{
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
        "application/pdf": [],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":[],
        "application/msword": [],
        "application/vnd.oasis.opendocument.text": []
      }}
      maxFiles={1}
      minSize={1}
      maxSize={maxSize}
      {...props}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject, fileRejections }) => (
        <div className="dropzone">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p>
              <img
                src={dragIcon(
                  document,
                  isDragActive,
                  isDragReject,
                  fileRejections.length > 0 && fileRejections[0].file.size > maxSize
                )}
                alt="Upload icoon"
              />
              {document !== undefined
                ? null :
                (
                  <span>
                    Klik hier of sleep een bestand naar hier.<br/>
                    Het bestand mag maximum 10 MB groot zijn.<br/>
                    Ondersteunde bestandstypen zijn: .pdf, .doc, .docx, .odt
                  </span>
                )
              }
              {document?.name}
            </p>
          </div>
        </div>
      )}
    </Dropzone>
  );
}