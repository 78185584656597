type RekeningTabelProps = {
  sortedKlassen: string[];
  totalen: Map<string, number>;
  klassen: Map<string, number>;
}

export default function RekeningTabel({ sortedKlassen, totalen, klassen }: RekeningTabelProps) {
  return (
    <table>
      <thead>
        <tr>
          <th>Klas</th>
          <th>Totaal bedrag</th>
          <th>Aantal leerlingen</th>
          <th>Bedrag per leerling</th>
        </tr>
      </thead>
      <tbody>
        {sortedKlassen.map(klas => (
          <KlasRekening
            key={klas}
            klas={klas}
            rekening={totalen.get(klas)!}
            aantalLeerlingen={klassen.get(klas)!}
          />
        ))}
      </tbody>
    </table>
  );
}

type KlasRekeningProps = {
  klas: string;
  rekening: number;
  aantalLeerlingen: number;
}

function KlasRekening({ klas, rekening, aantalLeerlingen }: KlasRekeningProps) {
  return (
    <tr>
      <td>{klas}</td>
      <td className="numeriek">€ {rekening.toFixed(2)}</td>
      <td className="numeriek">{aantalLeerlingen}</td>
      <td className="numeriek">€ {(rekening / aantalLeerlingen).toFixed(2)}</td>
    </tr>
  );
}