import { PrintBestelling } from "../../config/types";

export function validate(bestelling: PrintBestelling, document: File | undefined): Map<string, string> {
  const errors: Map<string, string> = new Map<string, string>();
  
  if (bestelling.klassen.length === 0) {
    errors.set("klassen", "Je moet minstens één klas selecteren");
  }

  if (bestelling.aantalExemplaren <= 0) {
    errors.set("aantalExemplaren", "Je moet minstens één exemplaar afdrukken.");
  }

  if (bestelling.id === undefined && document === undefined) {
    errors.set("document", "Je moet een document selecteren.");
  }

  if (bestelling.documentName === ""){
	errors.set("documentName","Je moet het document een naam geven.");
  }

  if (bestelling.aantalPaginas <= 0) {
    errors.set("aantalPaginas", "Je moet het aantal pagina's invullen.");
  }

  return errors;
}