import {
  addDoc,
  collection,
  doc,
  DocumentData,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  SnapshotOptions,
  updateDoc,
  where,
  orderBy,
  deleteDoc,
  QueryConstraint
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../config/database";
import { Campus, PrintBestelling, PrintStatus } from "../config/types";
import { deleteStorageDocument, saveStorageDocument } from "./storage";

const bestellingConverter = {
  toFirestore(bestelling: PrintBestelling): DocumentData {
    delete bestelling["id"];
    return bestelling;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): PrintBestelling {
    return snapshot.data(options) as PrintBestelling;
  }
};

const bestellingenCollection = collection(db, "bestellingen").withConverter(bestellingConverter);

export async function saveBestelling(bestelling: PrintBestelling, document: File | undefined) {
  if (document !== undefined) {
    // Het document kan undefined zijn als een bestaande bestelling bewerkt wordt.
    // In dat geval wordt het bestaande bestand behouden.
    const filePath = await saveStorageDocument(document);
    bestelling.documentPath = filePath;
  }

  if (bestelling.id === undefined) {
    await addDoc(
      bestellingenCollection,
      {...bestelling, besteldOp: new Date().toISOString()}
    );
  } else {
    const bestellingRef = doc(bestellingenCollection, bestelling.id);
    await updateDoc(bestellingRef, bestelling);
  }
}

type BestellingenQueryCriteria = {
  campus?: Campus;
  printStatus?: PrintStatus
  besteller?: string;
}

export function queryBestellingen({ campus, printStatus, besteller }: BestellingenQueryCriteria) {
  const constraints = [
    campus !== undefined ? where("campus", "==", campus) : undefined,
    printStatus !== undefined ? where("printStatus", "==", printStatus) : undefined,
    besteller !== undefined ? where("leerkracht", "==", besteller) : undefined
  ]
    .filter(x => x !== undefined)
    .map(x => x as QueryConstraint);
  return query(bestellingenCollection, orderBy("besteldOp"), ...constraints)
}

export function useBestellingen(criteria: BestellingenQueryCriteria) {
  const [ bestellingen, setBestellingen ] = useState<PrintBestelling[]>([]);

  useEffect(() => {
    const bestellingenQuery = queryBestellingen(criteria);
	
    const unsubscribe = onSnapshot(bestellingenQuery, data => {
      const convertedData = data.docs.map(d => ({...d.data(), id: d.id}));
      setBestellingen(convertedData);
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ criteria.besteller, criteria.printStatus, criteria.campus ]);

  return { bestellingen };
}

export async function setPrintStatus(bestellingId: string, printStatus: PrintStatus, printUitvoerder: string) {
  const bestellingRef = doc(bestellingenCollection, bestellingId);
  await updateDoc(bestellingRef, {
    printStatus,
    printUitvoerder: printStatus === PrintStatus.Ongeprint
      ? null
      : printUitvoerder
   });
}

export async function deleteBestelling(bestelling: PrintBestelling) {
  const bestellingRef = doc(bestellingenCollection, bestelling.id);
  await deleteStorageDocument(bestelling.documentPath);
  await deleteDoc(bestellingRef);
}


export function useBestelling(bestellingId: string) {
  const [ bestelling, setBestelling ] = useState<PrintBestelling | undefined>(undefined);

  useEffect(() => {
    const bestellingRef = doc(bestellingenCollection, bestellingId);
    const unsubscribe = onSnapshot(bestellingRef, bestellingDoc => {
      if (bestellingDoc.exists()) {
        setBestelling({
          ...bestellingDoc.data(),
          id: bestellingDoc.id
        } as PrintBestelling)
      }
    });
    return () => unsubscribe();
  }, [bestellingId]);

  return { bestelling, setPrintStatus };
}