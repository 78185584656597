import AdminHeader from "./AdminHeader";
import { Outlet } from "react-router-dom";
import "./style.scss";

export default () => {
  return (
    <>
      <AdminHeader />
      <Outlet/>
    </>
  );
}