import loader from "./loader.png";
import "./style.scss";

type Props = {
  inline?: boolean;
  size?: string;
};

export default ({ inline, size }: Props) => {
  const LoadingIcon = () => (
    <img
      alt="Loader icon"
      className="loader"
      src={loader}
      style={{
        ...(size ? { width: size, height: size } : {})
      }}
    />
  );

  if (inline) {
    return <LoadingIcon/>;
  } else {
    return (
      <div className="loaderDiv">
        <LoadingIcon/>
      </div>
    );
  }
}