// TODO: https://stackoverflow.com/questions/41055699/why-does-firebase-auth-work-for-chrome-but-not-firefox

import React, { PropsWithChildren, useEffect, useState } from "react";
import { app } from './firebase';
import { getAuth, OAuthProvider, onAuthStateChanged, signInWithRedirect, signOut, User } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { getIsAdmin } from "../database/admins";

export const auth = getAuth(app);

const provider = new OAuthProvider('microsoft.com');
provider.setCustomParameters({
  prompt: 'select_account',
  tenant: process.env.REACT_APP_AZURE_TENANT!
});

async function login() {
  document.location.hash = "redirecting";
  await signInWithRedirect(auth, provider);
}

async function logout() {
  await signOut(auth);
  window.location.reload();
}

type AuthContextType = {
  login: () => void;
  logout: () => void;
  user: User | null | undefined;
  isAdmin: Boolean | null | undefined;
}

export const AuthContext = React.createContext<AuthContextType>({
  login,
  logout,
  user: undefined,
  isAdmin: undefined
});

export const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
    const [ user, setUser ] = useState<User | null | undefined>(undefined);
    const [ isAdmin, setIsAdmin ] = useState<boolean | undefined>(undefined);
    const navigate = useNavigate();
  
    useEffect(() => {
      onAuthStateChanged(auth, async newUser => {
        setUser(newUser);

        if (newUser && !newUser?.email?.endsWith("@scheppersinstituut.be")) {
          // User is geen leerkracht
          navigate("/invalidLogin");
          logout();
        }

        if (newUser && isAdmin === undefined) {
          setIsAdmin(await getIsAdmin(newUser.email!));
        }

        if (newUser && document.location.hash === "#redirecting") {
          navigate("/");
        }
      });
    }, [isAdmin, navigate]);

    if (user === undefined) {
      return <Loader />;
    }
  
    return (
      <AuthContext.Provider value={{ user, login, logout, isAdmin }}>
        {children}
      </AuthContext.Provider>
    );
  }

// https://lo-victoria.com/introduction-to-react-context-api-with-firebase-authentication