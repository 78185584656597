import KlasUpdater from "../KlasUpdater";
import { Link } from "react-router-dom";
import "./style.scss";

export default () => {
  return (
    <ul className="adminHeader">
      <li><Link to={""}>Printopdrachten</Link></li>
      <li><Link to={"gebruikers"}>Admin gebruikers</Link></li>
      <KlasUpdater />
      <li><Link to={"rekeningen"}>Rekeningen</Link></li>
    </ul>
  );
}