import { useParams } from "react-router-dom"
import { useBestelling } from "../../../database/bestellingen";
import Bestelformulier from "../../Bestelformulier";
import Loader from "../../Loader";

export default () => {
  const { bestellingId } = useParams<{ bestellingId: string }>();

  if (bestellingId === undefined) {
    return <>Deze bestelling bestaat niet.</>;
  }

  const { bestelling } = useBestelling(bestellingId);

  if (bestelling === undefined) {
    return <Loader />;
  }

  return (
    <>
      <h1>Bestelling bewerken</h1>
      <Bestelformulier bestelling={bestelling} />
    </>
  );
}