import { useContext, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../config/auth";
import { PrintStatus } from "../../../config/types";
import { setPrintStatus, useBestelling, deleteBestelling } from "../../../database/bestellingen";
import { useStorageDocumentDownloadURL } from "../../../database/storage";
import "./style.scss";

export default () => {
  const { user } = useContext(AuthContext);
  const { bestellingId } = useParams<{ bestellingId: string }>();
  const { bestelling } = useBestelling(bestellingId!);
  const { downloadUrl } = useStorageDocumentDownloadURL(bestelling);
  const [ updatingBestelling, setUpdatingBestelling ] = useState<boolean>(false);
  const navigate = useNavigate();

  async function onUpdateStatus(printStatus: PrintStatus) {
    setUpdatingBestelling(true);
    await setPrintStatus(bestellingId!, printStatus, user?.email!);
    setUpdatingBestelling(false);
  }
  
  async function onDelete() {
    if (window.confirm("Ben je zeker dat je deze bestelling wilt verwijderen?")) {
      setUpdatingBestelling(true);
      await deleteBestelling(bestelling!);
      setUpdatingBestelling(false);
      navigate("/admin");
    }
  }

  if (bestelling === undefined) {
    return <>Deze print bestelling werd niet gevonden.</>;
  }

  const printUitvoerder = bestelling?.printUitvoerder?.replace("@scheppersinstituut.be", "");

  return <>
    <h1>
      Bestelling van&nbsp;
      {bestelling.leerkracht.replace("@scheppersinstituut.be", "")}
      {printUitvoerder
        ? `, afgedrukt door ${printUitvoerder}`
        : null
      }
    </h1>

    <table>
      <tbody>
        <tr>
          <th>Campus</th>
          <td>{bestelling.campus}</td>
        </tr>
        <tr>
          <th>Graag klaar tegen</th>
          <td>{bestelling.graagKlaarTegen}</td>
        </tr>
		<tr>
          <th>Naam document</th>
          <td>{bestelling.documentName}</td>
        </tr>
        <tr>
          <th>Klassen</th>
          <td>{bestelling.klassen.join(", ")}</td>
        </tr>
        <tr>
          <th>Aantal pagina's</th>
          <td>{bestelling.aantalPaginas}</td>
        </tr>
        <tr>
          <th>Aantal exemplaren</th>
          <td>{bestelling.aantalExemplaren}</td>
        </tr>
        <tr>
          <th>Zijden</th>
          <td>{bestelling.zijden}</td>
        </tr>
        <tr>
          <th>Nieten</th>
          <td>{bestelling.nieten}</td>
        </tr>
        <tr>
          <th>Grootte</th>
          <td>{bestelling.grootte}</td>
        </tr>
        <tr>
          <th>Perforeren</th>
          <td>{bestelling.perforeren}</td>
        </tr>
        <tr>
          <th>Gekleurd papier</th>
          <td>{bestelling.gekleurdPapier ? "ja" : "nee"}</td>
        </tr>
        <tr>
          <th>Meer uitleg</th>
          <td>{bestelling.meerUitleg}</td>
        </tr>
        {
          bestelling.printStatus === PrintStatus.InVerwerking || bestelling.printStatus === PrintStatus.Afgedrukt
          ? (
            <tr>
              <th>Print uitvoerder</th>
              <td>{printUitvoerder}</td>
            </tr>
          )
          : null
        }
      </tbody>
    </table>
    <a href={downloadUrl} target="_blank" rel="noreferrer" className="downloadDocument">
      Download document
    </a>

    <fieldset className="bestellingActies" disabled={updatingBestelling}>
      <button
        hidden={bestelling.printStatus !== PrintStatus.Ongeprint}
        onClick={() => onUpdateStatus(PrintStatus.InVerwerking)}
      >
        Markeren als in verwerking
      </button>
      <button
        hidden={bestelling.printStatus !== PrintStatus.InVerwerking}
        onClick={() => onUpdateStatus(PrintStatus.Afgedrukt)}
      >
        Markeren als afgedrukt
      </button>
      <button
        hidden={bestelling.printStatus !== PrintStatus.Afgedrukt}
        onClick={() => onUpdateStatus(PrintStatus.Ongeprint)}
      >
        Bestelling markeren als niet afgedrukt
      </button>

      <button onClick={() => onDelete()}>Bestelling verwijderen</button>
    </fieldset>
  </>;
}