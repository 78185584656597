import { app } from "../config/firebase";
import { v4 as uuid } from "uuid";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject, listAll } from "firebase/storage";
import { useEffect, useState } from "react";
import { PrintBestelling, PrintStatus } from "../config/types";
import { queryBestellingen } from "./bestellingen";
import { deleteDoc, getDocs } from "firebase/firestore";

const storage = getStorage(app, "gs://digitaal-kopiebriefje.appspot.com");

export async function saveStorageDocument(file: File) {
  const ext =  file.name.split('.').pop();
  const name = `${uuid()}.${ext}`;
  const storageRef = ref(storage, name);
  const response = await uploadBytes(storageRef, file);
  return response.ref.fullPath;
}

export async function deleteStorageDocument(path: string) {
  const storageRef = ref(storage, path);
  await deleteObject(storageRef);
}

export function useStorageDocumentDownloadURL(bestelling: PrintBestelling | undefined) {
  const [ downloadUrl, setDownloadUrl ] = useState<string | undefined>(undefined);

  useEffect(() => {
    (async () => {
      if (bestelling !== undefined) {
        const url = await getDownloadURL(ref(storage, bestelling.documentPath));
        setDownloadUrl(url); 
      }
    })();
  }, [ bestelling ]);

  return { downloadUrl };
}

export async function cleanDatabase() {
  // Afgeprinte bestellingen verwijderen
  const bestellingenQuery = queryBestellingen({ printStatus: PrintStatus.Afgedrukt });
  const afgedrukteBestellingen = await getDocs(bestellingenQuery);
  for (let bestelling of afgedrukteBestellingen.docs) {
    await deleteDoc(bestelling.ref);
  }

  // Storage objecten verwijderen die niet aan een bestelling gekoppeld zijn
  const restBestellingenQuery = queryBestellingen({});
  const restBestellingen = await getDocs(restBestellingenQuery);
  const bekendeDocumenten = restBestellingen.docs.map(b => b.data().documentPath);
  const alleDocumenten = await listAll(ref(storage));
  const orphanDocumenten = alleDocumenten.items.filter(d => !bekendeDocumenten.includes(d.fullPath));

  for (let orphanDocument of orphanDocumenten) {
    await deleteObject(orphanDocument);
  }
}