import { Outlet } from "react-router-dom";
import Header from "./Header";
import './style.scss';

export default () => {
	return (
		<section>
			<Header />

			<main>
				<Outlet />
			</main>
		</section>
	)
}