import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../config/auth";
import logo from "./schepperslogo.png";
import "./style.scss";

const AdminNav = () => {
  return (
    <>
      <li><Link to={"/admin"}>Admin</Link></li>
    </>
  );
};

const UserNav = () => {
  const { logout, isAdmin, user } = useContext(AuthContext);

  return (
    <nav>
      <ul>
        <li><Link to={"/"}>Bestelformulier</Link></li>
        <li><Link to={"/bestellingen"}>Mijn bestellingen</Link></li>
        { isAdmin ? <AdminNav/> : null }
        <li><Link to="#" onClick={logout}>{user?.displayName} afmelden</Link></li>
      </ul>
    </nav>
  );
}

export default () => {
  const { user } = useContext(AuthContext);

  return (
    <>
      <header>
        <div className="brand">
          <img alt="Scheppersinstituut logo" src={logo} />
          
          <div>
            <h1>Digitaal kopiebriefje</h1>
            <h2>Scheppersinstituut Deurne &amp; Antwerpen</h2>
          </div>
        </div>
        
        {user ? <UserNav /> : null}
      </header>
    </>
  );
}