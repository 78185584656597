import { useEffect, useState } from "react";
import CampusSelector from "../CampusSelector";
import { Campus, PrintStatus } from "../../config/types";
import { useBestellingen } from "../../database/bestellingen";
import BestellingenTabel from "./BestellingenTabel";

function lastCampus(campus? :Campus): Campus {
  const key = "lastCampus";
  if (campus !== undefined) {
    localStorage.setItem(key, campus);
  }
  return localStorage.getItem(key) as Campus;
}

export default () => {
  const [ campus, setCampus ] = useState<Campus>(lastCampus() || Campus.SMD);
  const { bestellingen } = useBestellingen({ campus });
  bestellingen.sort((a, b) => (a.graagKlaarTegen > b.graagKlaarTegen) ? 1 : -1)
  const ongeprint = bestellingen.filter(x => x.printStatus === PrintStatus.Ongeprint);
  const inVerwerking = bestellingen.filter(x => x.printStatus === PrintStatus.InVerwerking);
  const geprint = bestellingen.filter(x => x.printStatus === PrintStatus.Afgedrukt);

  useEffect(() => {
    lastCampus(campus);
  }, [campus]);

  return (
    <div>
      <CampusSelector value={campus} onChange={setCampus} />

      <fieldset>
        <h2>Ongeprinte bestellingen</h2>
        <BestellingenTabel bestellingen={ongeprint} />
      </fieldset>

      <fieldset>
        <h2>Bestellingen in verwerking</h2>
        <BestellingenTabel bestellingen={inVerwerking} />
      </fieldset>

      <fieldset>
        <h2>Afgedrukte bestellingen</h2>
        <BestellingenTabel bestellingen={geprint} />
      </fieldset>
    </div>
  );
}