import { MouseEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import Loader from "../../Loader";
import { klaslijstShouldUpdate, updateKlaslijst } from "../../../database/settings";

enum UpdateState {
  Inactive,
  Loading,
  Error
};

export default () => {
  const [ updating, setUpdating ] = useState<UpdateState>(UpdateState.Inactive);
  const [ errorMessage, setErrorMessage ] = useState<string | undefined>(undefined);

  useEffect(() => {
    (async () => {
      if(await klaslijstShouldUpdate()) {
        doUpdate();
      }
    })();
  }, []);

  async function doUpdate(e?: MouseEvent<HTMLAnchorElement>) {
    e?.preventDefault();
    setUpdating(UpdateState.Loading);    
    try {
      await updateKlaslijst();
      setUpdating(UpdateState.Inactive);
    } catch(e) {
      setErrorMessage(e as string);
      setUpdating(UpdateState.Error);
    }
  }

  return (
    <li>
      <Link to={"#"} onClick={doUpdate}>
        Klaslijst updaten
        {updating === UpdateState.Loading ? <Loader inline size="1em" /> : null }
        {updating === UpdateState.Error ? <span title={errorMessage}>⚠️</span> : null }
      </Link>
    </li>
  );
}