import { useState } from "react";
import "./style.scss";

type Props = {
  klassen: [string, number][];
  maxHeight: React.CSSProperties["maxHeight"];
  selectedKlassen: string[];
  onChange: (selectedKlassen: string[]) => void;
};

export default ({ klassen, maxHeight, selectedKlassen, onChange }: Props) => {
  const [ filter, setFilter ] = useState<string>("");

  return (
    <div className="klasSelector" style={{ maxHeight }}>
      <input
        type="text"
        placeholder="Filter klassen"
        onChange={e => setFilter(e.currentTarget.value)}
      />

      <div className="klasLijst">
        {klassen.map(([klas, aantal]) =>
          <label key={klas} hidden={!klas.toLowerCase().includes(filter.toLowerCase())}>
            <input
              type="checkbox"
              name="klassen"
              value={klas}
              hidden={!klas.toLowerCase().includes(filter.toLowerCase())}
              checked={selectedKlassen.includes(klas)}
              onChange={e => {
                const alreadySelected = selectedKlassen.includes(klas);

                if (alreadySelected) {
                  onChange(selectedKlassen.filter(x => x !== klas));
                } else {
                  onChange([ ...selectedKlassen, klas ]);
                }
              }}
            /> {klas} ({aantal} lln)
          </label>
        )}
      </div>
    </div>
  );
};