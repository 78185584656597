import {
  collection,
  onSnapshot,
  addDoc,
  deleteDoc,
  doc,
  query,
  where,
  getDocs
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../config/database";

type AdminUser = {
  id? : string;
  email: string;
}

const adminCollection = collection(db, "admins");

export async function getIsAdmin(email: string) {
  const adminCollection = collection(db, "admins");
  const q = query(adminCollection, where("email", "==", email));
  const result = await getDocs(q);
  return result.docs.length > 0;
}

export function useAdmins() {
  const  [ admins, setAdmins ] = useState<AdminUser[]>([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(adminCollection, admins => {
      const result = admins.docs.map(x => {
        return {...x.data(), id: x.id } as AdminUser;
      });
      setAdmins(result);
    });

    return () => unsubscribe();
  }, []);

  async function addAdmin(email: string) {
    const newAdmin: AdminUser = { email };
    await addDoc(adminCollection, newAdmin);
  }

  async function deleteAdmin(id: string) {
    const adminDoc = doc(adminCollection, id);
    return await deleteDoc(adminDoc);
  }

  return { admins, addAdmin, deleteAdmin };
}