import { FormEvent, useContext, useState } from "react";
import { useKlassen } from "../../database/settings";
import KlasSelector from "./KlasSelector";
import Dropzone from './Dropzone'
import "./style.scss";
import ErrorFeedback from "./ErrorFeedback";
import { saveBestelling } from "../../database/bestellingen";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../config/auth";
import Loader from "../Loader";
import CampusSelector from "../CampusSelector";
import { defaultBestelling, Grootte, Nieten, Perforeren, PrintBestelling, safeParseInt, Zijden } from "../../config/types";
import RadioInput from "./RadioInput";
import { validate } from "./validation";
import { DateTime } from "luxon";

type Props = {
  bestelling?: PrintBestelling;
}

export default ({ bestelling: propsBestelling }: Props) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { sortedKlassen } = useKlassen();
  const [ document, setDocument ] = useState<File>();
  const [ errors, setErrors ] = useState<Map<string, string>>(new Map<string, string>());
  const [ sendingForm, setSendingForm ] = useState<boolean>(false);
  const [ bestelling, setBestelling ] = useState<PrintBestelling>(
    propsBestelling !== undefined
    ? propsBestelling
    : { ...defaultBestelling, leerkracht: user!.email! } as PrintBestelling
  );
  console.log("propsBestelling", propsBestelling);
  console.log("bestelling", bestelling);

  function aantalLeerlingen(klasCodes: string[]) {
    const selectedKlassen = sortedKlassen.filter(([key]) => klasCodes.includes(key));
    const aantallen = selectedKlassen.map(([_, aantal]) => aantal);
    const total = aantallen.reduce((sum, aantal) => sum + aantal, 0);
    return total;
  }

  async function submit(e: FormEvent) {
    e.preventDefault();

    const errors = validate(bestelling, document);
    setErrors(errors)
    if (errors.size > 0) {
      return;
    }

    try {
      setSendingForm(true);
      const isEdit = bestelling.id !== undefined;
      await saveBestelling(bestelling, document);
      navigate("/bestellingVerstuurd" + (isEdit ? "?edit=true" : ""));
    } catch(e) {
      console.log(e);
      // Error tonen?
    } finally {
      setSendingForm(false);
    }
  }

  return (
    <form onSubmit={submit}>
      <CampusSelector
        value={bestelling.campus}
        onChange={campus => setBestelling({...bestelling, campus })}
        disabled={sendingForm}
      />

      <fieldset disabled={sendingForm}>
        <legend>Klas(sen)</legend>

        <KlasSelector
          klassen={sortedKlassen}
          maxHeight="200px"
          selectedKlassen={bestelling.klassen}
          onChange={klassen => {
            setBestelling({
              ...bestelling,
              klassen,
              aantalExemplaren: aantalLeerlingen(klassen)
            });
          }}
        />
        <ErrorFeedback name="klassen" errors={errors} />

        <div className="topSpace">
          Aantal exemplaren:
          <input
            name="aantalExemplaren"
            type="number"
            value={bestelling.aantalExemplaren}
            onChange={e => setBestelling({
              ...bestelling,
              aantalExemplaren: safeParseInt(e.currentTarget.value)
            })}
            required
          />
          <ErrorFeedback name="aantalExemplaren" errors={errors} />
        </div>
      </fieldset>

      <fieldset disabled={sendingForm}>
        <legend>Document</legend>
        
        <Dropzone
          onDrop= {acceptedFiles => {
			bestelling.documentName = acceptedFiles[0].name;
			setDocument(acceptedFiles[0]);
			}
		  }  
          document={document}
        />
		
        <ErrorFeedback name="document" errors={errors} />

		<div className="topSpace">
          Naam van het document:
          <input
            name="documentName"
            value={bestelling.documentName}
			
			onChange={e => setBestelling({
              ...bestelling,
              documentName: e.currentTarget.value
			})
			}
          />
          <ErrorFeedback name="documentName" errors={errors} />
        </div>
		
        <div className="topSpace">
          Hoeveel pagina's heeft dit document?
          <input
            type="number"
            step="1"
            name="aantalPaginas"
            value={bestelling.aantalPaginas}
            onChange={e => setBestelling({
              ...bestelling,
              aantalPaginas: safeParseInt(e.currentTarget.value)
            })}
          />
          <ErrorFeedback name="aantalPaginas" errors={errors} />
        </div>
      </fieldset>

      <fieldset disabled={sendingForm}>
        <legend>Extra opties</legend>

        <RadioInput
          name="zijden"
          label="Zijden"
          value={bestelling.zijden}
          onChange={zijden => setBestelling({ ...bestelling, zijden })}
          options={[
            { label: "Recto", value: Zijden.Recto },
            { label: "Rectoverso", value: Zijden.Rectoverso }
          ]}
        />

        <RadioInput
          name="nieten"
          label="Nieten"
          value={bestelling.nieten}
          onChange={nieten => setBestelling({ ...bestelling, nieten })}
          options={[
            { label: "Nee", value: Nieten.Nee },
            { label: "Ja, enkel", value: Nieten.Enkel },
            { label: "Ja, dubbel", value: Nieten.Dubbel }
          ]}
        />

        <RadioInput
          name="grootte"
          label="Grootte"
          value={bestelling.grootte}
          onChange={grootte => setBestelling({ ...bestelling, grootte })}
          options={[
            { label: "Niet wijzigen", value: Grootte.Behouden },
            { label: "Vergroten", value: Grootte.Vergroten },
            { label: "Verkleinen", value: Grootte.Verkleinen }
          ]}
        />

        <RadioInput
          name="perforeren"
          label="Perforeren"
          value={bestelling.perforeren}
          onChange={perforeren => setBestelling({ ...bestelling, perforeren })}
          options={[
            { label: "Niet perforeren", value: Perforeren.Nee },
            { label: "Twee gaatjes", value: Perforeren.TweeGaatjes },
            { label: "Vier gaatjes", value: Perforeren.VierGaatjes }
          ]}
        />
      
        <p>
          <label>
            <input
              type="checkbox"
              checked={bestelling.gekleurdPapier} 
              onChange={() => setBestelling({
                ...bestelling,
                gekleurdPapier:
                !bestelling.gekleurdPapier
              })}
            />
            Gekleurd papier
          </label>
        </p>

        <div className="topSpace">
          Graag klaar tegen:
          <input
            type="date"
            value={bestelling.graagKlaarTegen}
            onChange={e => setBestelling({
              ...bestelling,
              graagKlaarTegen: e.currentTarget.value
            })}
            min={DateTime.now().toSQLDate()}
          />
        </div>

        <div className="topSpace">
          Schrijf hier meer uitleg over uw kopieen:
          <textarea
            value={bestelling.meerUitleg}
            onChange={e => setBestelling({
              ...bestelling,
              meerUitleg: e.currentTarget.value
            })}
          />
        </div>
      </fieldset>

      {errors.size > 0
        ? <span className="validationError" style={{ textAlign: "center" }}>
            Er zijn nog fouten in het formulier
          </span>
        : null
      }
      <button type="submit" disabled={sendingForm}>
        {sendingForm
          ? <Loader inline size="1.5em" />
          : "Bestelling opsturen"
        }
      </button>
    </form>
  );
}