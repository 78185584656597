export enum Campus {
  SMD = "SMD",
  SEA = "SEA",
  SED = "SED",
};

export enum Zijden {
  Recto = "recto",
  Rectoverso = "rectoverso"
}

export enum Nieten {
  Nee = "nee",
  Enkel = "enkel",
  Dubbel = "dubbel"
}

export enum Grootte {
  Behouden = "behouden",
  Vergroten = "vergroten",
  Verkleinen = "verkleinen"
}

export enum Perforeren {
  Nee = "nee",
  TweeGaatjes = "twee",
  VierGaatjes = "vier"
}

export enum PrintStatus {
  Ongeprint = "ongeprint",
  InVerwerking = "inverwerking",
  Afgedrukt = "afgedrukt"
}

export type PrintBestelling = {
  id: string | undefined;
  campus: Campus;
  klassen: string[];
  aantalExemplaren: number;
  documentPath: string;
  documentName: string;
  aantalPaginas: number;
  zijden: Zijden;
  nieten: Nieten;
  grootte: Grootte;
  perforeren: Perforeren;
  gekleurdPapier: boolean;
  graagKlaarTegen: string;
  meerUitleg: string;
  besteldOp: string;
  leerkracht: string;
  printStatus: PrintStatus;
  printUitvoerder: string | null;
};

export const defaultBestelling = {
  id: undefined,
  campus: Campus.SMD,
  klassen: [],
  aantalExemplaren: 0,
  documentPath: "",
  documentName: "",
  aantalPaginas: 0,
  zijden: Zijden.Rectoverso,
  nieten: Nieten.Nee,
  grootte: Grootte.Behouden,
  perforeren: Perforeren.Nee,
  gekleurdPapier: false,
  graagKlaarTegen: "",
  meerUitleg: "",
  besteldOp: "",
  leerkracht: "",
  printStatus: PrintStatus.Ongeprint,
  printUitvoerder: null
};

export type Prijzen = {
  [Zijden.Recto]: number;
  [Zijden.Rectoverso]: number;
};

export type Rekeningen = Map<string, number>;

export function safeParseInt(text: string, def: number = 0) {
  try {
    const result = parseInt(text);
    return isNaN(result) ? def : result;
  } catch(e) {
    return def;
  }
}

export function safeParseFloat(text: string, def: number = 0) {
  try {
    const result = parseFloat(text);
    return isNaN(result) ? def : result;
  } catch(e) {
    return def;
  }
}