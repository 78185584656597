import { Link } from "react-router-dom";
import stap1 from "./stap1.png";
import stap2 from "./stap2.png";

export default () => {
  return (
    <p>
      Als je Firefox gebruikt, volg dan deze stappen:
      <ol>
        <li style={{ margin: "1em" }}>
          Klik op dit icoon linksboven in de adresbalk<br/>
          <img alt="Stap 1" src={stap1} />
        </li>
        <li style={{ margin: "1em" }}>
          In het menu dat open gaat zet je "Enhanced Tracking Protection" uit.<br/>
          <img alt="Stap 2" src={stap2} />
        </li>
        <li>
          Ga nu terug naar de <Link to="/login">login pagina</Link> en log opnieuw in.
        </li>
      </ol>
    </p>
  );
};