import { Prijzen, PrintStatus, Rekeningen, safeParseFloat, Zijden } from "../../../config/types";
import { useBestellingen } from "../../../database/bestellingen";
import { savePrijzen, useKlassen, usePrijzen } from "../../../database/settings";
import Loader from "../../Loader";
import { rekeningen } from "./calculator";
import PrintBeheer from "./Printbeheer";
import RekeningTabel from "./RekeningTabel";
import "./style.scss";

export default () => {
  const { prijzen, setPrijzen } = usePrijzen();
  const { klassen } = useKlassen();
  const { bestellingen } = useBestellingen({ printStatus: PrintStatus.Afgedrukt });

  if (!prijzen) {
    return <Loader />
  }

  const totalen = rekeningen(bestellingen, klassen, prijzen);
  const sortedKlassen = Array.from(totalen.keys()).sort((a, b) => a[0].localeCompare(b[0]));
  
  return (
    <>
      <PrijzenEditor
        prijzen={prijzen}
        setPrijzen={setPrijzen}
      />
      <RekeningenLijst
        sortedKlassen={sortedKlassen}
        klassen={klassen}
        totalen={totalen}
      />
      <PrintBeheer />
    </>
  );
}

type PrijzenProps = {
  prijzen: Prijzen;
  setPrijzen: React.Dispatch<Prijzen>;
}

function PrijzenEditor({ prijzen, setPrijzen }: PrijzenProps) {
  return (
    <fieldset className="prijzen">
      <h1>Prijzen (in €)</h1>
      <table>
        <tbody>
          <tr>
            <th>Gewone kopie</th>
            <td>
              <span>€</span>
              <input
                type="number"
                step="0.01"
                value={prijzen[Zijden.Recto]}
                onChange={e => setPrijzen({
                  ...prijzen,
                  [Zijden.Recto]: safeParseFloat(e.currentTarget.value)
                })}
              /> 
            </td>
          </tr>
          <tr>
            <th>Rectoverso kopie</th>
            <td>
              <span>€</span>
              <input
                type="number"
                step="0.01"
                value={prijzen[Zijden.Rectoverso]}
                onChange={e => setPrijzen({
                  ...prijzen,
                  [Zijden.Rectoverso]: safeParseFloat(e.currentTarget.value)
                })}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <button onClick={() => {
        savePrijzen(prijzen);
      }}>Prijzen opslagen</button>
    </fieldset>
  );
}

type RekeningenProps = {
  sortedKlassen: string[];
  klassen: Map<string, number>;
  totalen: Rekeningen;
}

function RekeningenLijst({ sortedKlassen, klassen, totalen }: RekeningenProps) {
  return (
    <fieldset>
      <h1>Rekeningen</h1>

      { sortedKlassen.length > 0
        ? <RekeningTabel
            sortedKlassen={sortedKlassen}
            klassen={klassen}
            totalen={totalen}
          />
        : (
          <p>
            Er zijn geen bestellingen gemarkeerd als zijnde "afgedrukt".<br/>
            Rekeningen kunnen alleen berekend worden voor de bestellingen die zijn afgedrukt.
          </p>
        )
      }
    </fieldset>
  );
}