import {
  collection,
  doc,
  DocumentData,
  getDoc,
  onSnapshot,
  QueryDocumentSnapshot,
  setDoc,
  SnapshotOptions,
  updateDoc
} from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { db } from "../config/database";
import { app } from "../config/firebase";
import { Prijzen } from "../config/types";

export type Klaslijst = {
  lastUpdated: Date;
  lijst: Map<string, number>;
}

const klassenConverter = {
  toFirestore(settings: Klaslijst): DocumentData {
    return {
      lastUpdated: settings.lastUpdated.toISOString(),
      lijst: Object.fromEntries(settings.lijst.entries())
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Klaslijst {
    const data = snapshot.data(options)!;
    return {
      lastUpdated: new Date(data.lastUpdated),
      lijst: new Map<string, number>(Object.entries(data.lijst))
    };
  }
};

const settingsCollection = collection(db, "settings");
const klasLijstRef = doc(
  settingsCollection,
  "klassen"
).withConverter(klassenConverter);
const prijzenRef = doc(
  settingsCollection,
  "prijzen"
);

export async function klaslijstShouldUpdate() {
  const data = (await getDoc(klasLijstRef)).data();
  const lastUpdated = data ? new Date(data?.lastUpdated) : new Date();
  const msElapsed = new Date().getTime() - lastUpdated.getTime();
  const halfDayInMs = 12*3600*1000;
  
  return msElapsed > halfDayInMs;
}

export async function updateKlaslijst() {
  const functions = getFunctions(app, "europe-west1");
  if (process.env.NODE_ENV === "development") {
    connectFunctionsEmulator(functions, "localhost", 5001);
  }
  const klassen = httpsCallable<{}, [string, number][]>(functions, "klassen");
  const result = await (await klassen()).data;
  const lijst = new Map<string, number>(result);
  
  await setDoc(klasLijstRef, {
    lastUpdated: new Date(),
    lijst
  });
}

export function useKlassen() {
  let [ klassen, setKlassen ] = useState<Map<string, number>>(new Map<string, number>());
  useEffect(() => {
    const unsubscribe = onSnapshot(klasLijstRef, settingsDoc => {
      const settings = settingsDoc.data();
      if (settings) {
        setKlassen(settings.lijst);
      }
    });
    return () => unsubscribe();
  }, []);
  klassen.set(" Eigen kopie, GOK, ...",1);
  const sortedKlassen = Array.from(klassen.entries()).sort((a, b) => a[0].localeCompare(b[0]))
  return { sortedKlassen, klassen };
}

export function usePrijzen() {
  const [ prijzen, setPrijzen ] = useState<Prijzen>();

  useEffect(() => {
    const unsubscribe = onSnapshot(prijzenRef, prijzenDoc => {
      const prijzen = prijzenDoc.data();
      if (prijzen){
        setPrijzen(prijzen as Prijzen);
      }
    });
    return () => unsubscribe();
  }, []);

  return { prijzen, setPrijzen };
}

export async function savePrijzen(prijzen: Prijzen) {
  await updateDoc(prijzenRef, prijzen);
}