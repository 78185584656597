import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../config/auth";
import { PrintBestelling, PrintStatus } from "../../config/types";
import { deleteBestelling, useBestellingen } from "../../database/bestellingen";

export default () => {
  const { user } = useContext(AuthContext);
  const { bestellingen } = useBestellingen({ besteller: user?.email! });
  const sortedBestellingen = bestellingen.sort((a, b) =>
    DateTime.fromISO(b.besteldOp).toMillis() - DateTime.fromISO(a.besteldOp).toMillis()
  );

  return (
    <fieldset>
      <h1>Mijn bestellingen</h1>
      <table className="bestellingen">
        <thead>
          <tr>
            <th>Campus</th>
			<th>Naam document</th>
            <th>Datum bestelling</th>
            <th>Klassen</th>
            <th>Status</th>
            <th/>
            <th/>
          </tr>
        </thead>
        <tbody>
          {sortedBestellingen.map(b => <BestellingRow key={b.id} bestelling={b} />)}
        </tbody>
      </table>
    </fieldset>
  );
}

function BestellingRow({ bestelling }: { bestelling: PrintBestelling }) {
  function doDeleteBestelling(e: React.MouseEvent) {
    e.preventDefault();
    if (window.confirm("Ben je zeker dat je deze bestelling wil verwijderen?")) {
      deleteBestelling(bestelling);
    }
  }

  return (
    <tr>
      <td>{bestelling.campus}</td>
	  <td>{bestelling.documentName}</td>
      <td title={bestelling.besteldOp}>{DateTime.fromISO(bestelling.besteldOp).toRelative()}</td>
      <td>{bestelling.klassen.join(", ")}</td>
      <td>
        <BestelStatus printStatus={bestelling.printStatus} />
      </td>
      <td>
        {bestelling.printStatus === PrintStatus.Ongeprint
          ? <Link to={bestelling.id!} title="Bestelling bewerken">
              <FontAwesomeIcon icon={regular("pen-to-square")} />
            </Link>
          : null
        }        
      </td>
      <td>
        {bestelling.printStatus === PrintStatus.Ongeprint
          ? <Link to="#" title="Bestelling verwijderen" onClick={doDeleteBestelling}>
              <FontAwesomeIcon icon={solid("trash-can")} />
            </Link>
          : null
        }        
      </td>
    </tr>
  );
}

function BestelStatus({ printStatus }: { printStatus: PrintStatus }) {
  switch (printStatus) {
    case PrintStatus.Ongeprint:
      return <><FontAwesomeIcon icon={regular("clock")}/> Nog niet gedrukt</>;
    case PrintStatus.InVerwerking:
      return <><FontAwesomeIcon icon={solid("print")}/> Bezig met drukken</>;
    case PrintStatus.Afgedrukt:
      return <><FontAwesomeIcon icon={solid("check")}/> Klaar voor afhaling</>;
  }
}