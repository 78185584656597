import { Prijzen, PrintBestelling, Rekeningen, Zijden } from "../../../config/types";

function round(x: number, decimals: number) {
  const factor = 10 ** decimals;
  return Math.round(x * factor) / factor;
}

export function bestellingRekening(
  bestelling: PrintBestelling,
  klassen: Map<string, number>,
  prijzen: Prijzen
): Rekeningen {
  const totaalAantalLln = bestelling.klassen
    .map(klas => klassen.get(klas)!)
    .reduce((som, aantalLln) => som + aantalLln, 0);
  const paginasPerLln = bestelling.zijden === Zijden.Recto ? bestelling.aantalPaginas : Math.ceil(bestelling.aantalPaginas / 2);
  const totaalAantalPaginas = bestelling.aantalExemplaren * paginasPerLln;
  const totalePrijs = totaalAantalPaginas * prijzen[bestelling.zijden];
  const prijzenPerKlas = bestelling.klassen.map(klas => {
    const ratio = klassen.get(klas)! / totaalAantalLln;
    const klasPrijs = round(ratio * totalePrijs, 7);
    return [klas, klasPrijs] as [string, number];
  });

  return new Map<string, number>(prijzenPerKlas);
}

export function rekeningen(
  bestellingen: PrintBestelling[],
  klassen: Map<string, number>,
  prijzen: Prijzen
): Rekeningen {
  const subRekeningen = bestellingen.map(b => bestellingRekening(b, klassen, prijzen));
  const klasTotalen = Array
    .from(klassen.keys())
    .map(klas => {
      const klasBedragen = subRekeningen.map(r => r.get(klas) || 0);
      const totaal = klasBedragen.reduce((som, bedrag) => som + bedrag, 0);
      return [klas, totaal] as [string, number];
    })
    .filter(([_, totaal]) => totaal > 0);

  return new Map<string, number>(klasTotalen);
}

