import { PropsWithChildren, useContext } from "react";
import { Navigate,  } from "react-router-dom";
import { AuthContext } from "../../config/auth";

// https://www.robinwieruch.de/react-router-private-routes/

export default({ children, adminOnly }: PropsWithChildren<{ adminOnly?: boolean }>) => {
	const { user, isAdmin } = useContext(AuthContext);

	if (user === null) {
		return <Navigate replace to="/login" />;
	}
	
	if (user && adminOnly && isAdmin === false) {
		return <Navigate replace to="/" />;
	}
	
	return <>{children}</>;
}