import { PrintBestelling } from "../../../config/types";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

type Props = {
  bestellingen: PrintBestelling[];
}

export default ({ bestellingen }: Props) => {
  if (bestellingen.length === 0) {
    return <div>Geen bestellingen gevonden</div>;
  } 

  return (
    <table className="bestellingen">
      <thead>
        <tr>
          <th>Graag klaar tegen</th>
		  <th>Naam document</th>
		  <th>Datum bestelling</th>
          <th>Besteller</th>
          <th>Aantal blz</th>
          <th/>
        </tr>
      </thead>
      <tbody>
        {bestellingen.map(b => <BestellingRow key={b.id} bestelling={b} />)}
      </tbody>
    </table>
  );
}

function BestellingRow({ bestelling }: { bestelling: PrintBestelling }) {
  return (
    <tr>
      <td title={bestelling.graagKlaarTegen}>
        {DateTime.fromISO(bestelling.graagKlaarTegen).toRelative()}
      </td>
	  <td title={bestelling.documentName}>{bestelling.documentName}</td>
	  <td title={bestelling.besteldOp}>{DateTime.fromISO(bestelling.besteldOp).toRelative()}</td>
      <td>{bestelling.leerkracht?.replace("@scheppersinstituut.be", "")}</td>
      <td>{bestelling.aantalPaginas * bestelling.aantalExemplaren}</td>
      <td><Link to={bestelling.id!}><FontAwesomeIcon icon={solid("magnifying-glass")} /></Link></td>
    </tr>
  );
}