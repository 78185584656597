import { useContext } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../../config/auth";
import "./style.scss";
import loginButton from "./microsoft-login.png";

export default () => {
	const { login, user } = useContext(AuthContext);
	const location = useLocation();
	const redirecting = location.hash === "#redirecting";

	if (redirecting) {
		return (
      <p>
        Een ogenblik geduld. Je wordt zo meteen doorgestuurd.<br/>
        Als je langer moet wachten dan 5 seconden, volg dan de instructies <Link to="/firefoxFix">hier</Link>.
      </p>
    );
	}

	if (user !== null) {
		return <Navigate to={"/"} />
	}

	return (
		<>
			<p className="loginWarning">
				Om deze website te kunnen gebruiken moet je inloggen met je Scheppers account.
			</p>
			
			<button id="loginButton" onClick={login}>
        		<img alt="Microsoft login button" src={loginButton}/>
      		</button>
		</>
	);
}