import { useState } from "react";
import { useAdmins } from "../../database/admins";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export default () => {
  const { admins, addAdmin, deleteAdmin } = useAdmins();
  const [ newUser, setNewUser ] = useState("");

  async function addUser() {
    const trimmedNewUser = newUser.trim();
    if (!trimmedNewUser.endsWith("@scheppersinstituut.be")) {
      alert(`Het emailadres moet eindigen op "@scheppersinstituut.be"`);
      return;
    }

    if (admins.some(x => x.email === trimmedNewUser)) {
      alert("Dit emailadres zit reeds in de administrator lijst");
      return;
    }

    await addAdmin(newUser);
    setNewUser("");
  }

  async function removeUser(id: string) {
    if (window.confirm("Ben je zeker dat je deze gebruiker wil verwijderen als administrator?")) {
      return deleteAdmin(id);
    }
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Gebruiker email</th>
          <th>Verwijderen</th>
        </tr>
      </thead>
      <tbody>
        {admins.map(admin => (
          <tr key={admin.id}>
            <td>{admin.email}</td>
            <td>
              <button onClick={() => removeUser(admin.id!)}>
                <FontAwesomeIcon icon={solid("trash-can")} />
              </button>
            </td>
          </tr>
        ))}
        <tr>
          <td>
            <input
              value={newUser}
              onChange={e => setNewUser(e.currentTarget.value)}
              onKeyUp={e => {
                if (e.key === "Enter") {
                  addUser();
                }
              }}
            />
          </td>
          <td>
            <button onClick={addUser}>
              <FontAwesomeIcon icon={solid("plus")} />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
}