import { useState } from "react";
import { cleanDatabase } from "../../../database/storage";
import Loader from "../../Loader";

export default function PrintBeheer() {
  const [ cleaningDatabase, setCleaningDatabase ] = useState<boolean>();
  
  async function doCleanDatabase() {
    if (window.confirm("Ben je zeker dat je de afgedrukte bestellingen wil verwijderen?\n Dit kan niet worden ongedaan gemaakt!")) {
      setCleaningDatabase(true);
      await cleanDatabase();
      setCleaningDatabase(false);
    }
  }

  return (
    <fieldset>
      <h1>Printbeheer</h1>

      <button
        onClick={doCleanDatabase}
        disabled={cleaningDatabase}
      >
        Verwijder afgedrukte bestellingen { cleaningDatabase ? <Loader inline size="1.5em"/> : null}
      </button>

      <p>
        Dit zal alle afgedrukte bestellingen verwijderen uit de database.<br/>
        Deze gegevens zullen dus niet meer ingekeken kunnen worden hierna.<br/>
        Voer deze actie uit nadat de rekeningen naar de leerlingen zijn uitgestuurd.
      </p>
    </fieldset>
  );
}