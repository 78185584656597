import "./style.scss";

type RadioOption<T> = {
  value: T;
  label: string;
}

type Props<T> = {
  name: string;
  label: string;
  options: RadioOption<T>[];
  value: T;
  onChange: (newValue: T) => void;
}

export default <T extends string>({ name, label, options, value, onChange }: Props<T>) => {
  return (
    <p className="radioInput">
      <strong>{label}</strong>:

      {options.map(({ value: optionValue, label }) => (
        <label key={`${name}-${optionValue}`}>
          <input
            name={name}
            value={optionValue}
            type="radio"
            checked={optionValue === value}
            onChange={e => onChange(e.currentTarget.value as T)}
          />
          {label}
        </label>
      ))}
    </p>
  );
}