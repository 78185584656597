import { Campus } from "../config/types";

type Props = {
  value: Campus;
  onChange?: (campus: Campus) => void;
  disabled?: boolean;
}

export default ({ value, onChange, disabled }: Props) => {
  return (
    <fieldset disabled={disabled}>
      <legend>Campus</legend>
      <select
        name="campus"
        value={value}
        onChange={e =>
          onChange
          ? onChange(e.currentTarget.value as Campus)
          : null
        }>
        <option value={Campus.SMD}>{Campus.SMD}</option>
        <option value={Campus.SEA}>{Campus.SEA}</option>
        <option value={Campus.SED}>{Campus.SED}</option>
      </select>
    </fieldset>
  );
}